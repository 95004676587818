.DevInfo {
    .dev-info {
        background: white;
        margin: 30px auto;
        width: 80%;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

        .header {
            padding: 20px;
            border-bottom: 1px solid #d3d3d3;
            h1 {
                font-size: 1.2rem;
                margin: 0;
            }
        }

        .body {
            padding: 20px;
        }

        code {
            font-size: 1.1rem;
        }

        .responsive-table {
            overflow-x: scroll;
        }

        @media (max-width: 800px) {
            width: 100%;
        }
    }
}
