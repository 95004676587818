.ContractingAuthorityBox {
    .contact {
        margin: 10px 0;
    }

    .contact-item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .icon {
            margin-right: 16px;
        }

        &.mirrored {
            flex-direction: row-reverse;
            .value {
                text-align: right;
            }
            .icon {
                margin-right: 0;
                margin-left: 16px;
            }
        }
    }
}
