.MainLayout {
    height: 100svh;
    // min-height: 100vh;
    display: grid;
    grid-template-columns: 64px 1fr;
    grid-template-rows:  1fr;
    grid-template-areas:
        // "navbar header"
        "navbar main";

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        // height: 100vh;

        .navbar-container {
            display: none;
        }
        .mobile-header {
            display: block;
        }
        .bottom-navigation {
            display: block;
        }
        .main-content-container {
            min-height: calc(100svh - 52px);
            flex-grow: 1;
            padding-top: 0px;
            overflow-y: auto;
            max-width: 100vw
        }
    }
}

.bottom-navigation {
    display: none;
}

.navbar-container {
    grid-area: navbar;
    z-index: 1300;
}

.header-container {
    grid-area: header;
}

.mobile-header {
    display: none;
}

.main-content-container {
    grid-area: main;
    // box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    max-width: calc(100vw - 64px);
    min-height: calc(100svh - 64px);
    padding-top: 64px;
    overflow-y: auto;
}

@media print {
    .MainLayout {
        min-height: unset;
        display: block;
    }

    .header-container {
        display: none;
    }
    .navbar-container {
        display: none;
    }
    .main-content-container {
        box-shadow: none;
    }
}
