@import url("https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf_viewer.min.css");

.viewer {
    width: 100%;
    height: 100%;
    img {
      display: block;
      margin: auto;
    }
  }

.pdfViewer .page {
    // width: 100vw;
    height: 100vh;
    margin: 0 auto;
    // position: relative;
    // overflow: visible;
    border: 0;
    // background-clip: content-box;
    // -o-border-image: url(images/shadow.png) 9 9 repeat;
    border-image: none
    // background-color: #fff;
}

.foundItem {
    background-color: #225E4D;
    padding-bottom: 3px;
}

