.InViewPagination {
    display: flex;
    align-items: center;

    .page-info {
        padding: 0 12px;
        text-align: center;
        font-size: 0.7rem;

        .page-number {
        }
        .list-type {

        }
    }
}
