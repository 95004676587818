.boxHeader {
    font-size: 0.875rem;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.highlight {
    width: 100%;
    height: 4px;
}

.current {
    background: var(--themeCurrent);
}
.blue {
    background: var(--themeBlue);
}
.yellow {
    background: var(--themeYellow);
}
.pink {
    background: var(--themePink);
}
.green {
    background: var(--themeGreen);
}
.grey {
    background: var(--themeDarkGray);
}
