.LoginHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;   
}

.logo {
    max-width: 300px;
}
