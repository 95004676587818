.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }
  
  
  /* VARIABLES */
  
  :root {
    --main-color: #04325e;
    --text-color: #000;
    --text-bit-darker: #7d7d7d; 
    --text-color-light: rgb(216, 216, 216);
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }
  
  
  /* GENERAL */
  
  * {
    box-sizing: border-box;
  }
  
  body {
      font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
      font-size: 1em;
      font-weight: 300;
      line-height: 1.5;
      color: var(--text-color);
      background: var(--bg-color);
      position: relative;
  }
  
  
  main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
  }

  .height {
      height: 10.5em;
  }
  

  /* GRID */
  
  .rowDay {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height:3em;
  }

  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }

/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
  }
  
  .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
  }
  
  .calendar .header .icon:first-of-type {
    margin-left: 1em;
  }
  
  .calendar .header .icon:last-of-type {
    margin-right: 1em;
  }
  
  .calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-bit-darker);
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .cell {
    position: relative;
    // height: calc((75vh -104px) /5);
    // height: 10.5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
  
  .calendar .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }

  
  .calendar .body .row {
    border-bottom: 1px solid var(--border-color);
  }

  .calendar .body .rowDay {
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .row:last-child {
    border-bottom: none;
  }

  .calendar .body .rowDay:last-child {
    border-bottom: none;
  }
  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;

  }
  .calendar .body .cell .today {
    position: absolute;
    font-size: 82.5%;
    top: .65em;
    right: .75em;
    font-weight: 700;
    border-radius: 8px;
    background-color: #04325e;
    color: #fff;
    height: 17px;
    width: 17px;
    text-align: center;
  }

  .calendar .body .cell .weeknumber {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    left: .75em;
    font-weight: 700;
  }
  
  .calendar .body .disabled {
    color: var(--text-color-light);
    // pointer-events: none;
  }

  .calendar .body .weekend {
    color: var(--text-color-light);
    pointer-events: none;
    background-color: #f8f9fb;
  }
  
  .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
    z-index: 1;
    pointer-events: none;
  }
  
  .calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
    opacity: 0.05;
    transition: .5s ease-in;
  }
  
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
  }
  @media only screen and (max-width: 600px) {
    .calendar .body .col {
        flex-grow: 0;
        flex-basis: calc(100%/5);
        width: calc(100%/5);
       
      }
  }
