@use "./variables.scss";
@use 'sass:color';

:root {
    --themeBlue: rgb(23, 51, 87); // #173357; 
    // original:--themePink: #e03660;
    --themePink: rgb(224, 54, 96); // #E03660;
    --themeGreen: rgb(34, 94, 77); // #225E4D;
    --themeYellow: rgb(245, 113, 23); // #F57117;
    --themeDarkGray: rgb(112, 112, 112); // #707070;
    --themeBlack: rgb(51, 51, 51); // #333;
    --themeBackground:rgb(244, 244, 244); // #f4f4f4;

    /* This is the current active theme color, use this color in other stylesheets
     * to get your color updated per module page */
    --themeCurrent: var(--themeDarkGray);
}

html {
    box-sizing: border-box;
    overflow: hidden;
    @media (max-width: 800px){
        /* height: 100%!important; */
        // overflow: auto!important;
        overflow: hidden;
        max-height: 100vh !important;
    }
}

body {
    margin: 0;
    padding: 0;
    background: var(--themeBackground);
    color: var(--themeBlack);
    font-family: "Maven Pro", system-ui, sans-serif;
    font-size: 14px;

    @media (max-width: 800px){
        /* height: 100%!important; */
        overflow: auto!important;
        max-height: 100vh !important;
    }
}

* {
    box-sizing: inherit;
}

p {
    line-height: 1.5em;
}

h1.module-title {
    margin: 0;
    font-size: 1.9rem;
    font-weight: normal;
    margin-bottom: 10px;
    line-height: 1.1em;
}

strong {
    font-weight: 600;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1rem;
}

h4 {
    font-size: 0.8rem;
    margin-bottom: 4px;
    font-weight: normal;
    color: color.adjust(black, $lightness: 20%);
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.flex {
    display: flex;

    &.centered {
        justify-content: center;
    }
    &.v-centered {
        align-items: center;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.right {
        justify-content: flex-end;
    }
    &.jcfe {
        justify-content: flex-end;
    }
    &.jcsb {
        justify-content: space-between;
    }
    &.column {
        flex-direction: column;
    }
    &.aifs {
        align-items: flex-start;
    }
    &.aic {
        align-items: center;
    }
    &.aife {
        align-items: flex-end !important;
    }
    &.stretch {
        align-items: stretch;
    }
}

.fg1 {
    flex-grow: 1;
}

.responsive-container {
    padding: 0 40px;

    @media (max-width: 1400px) {
        padding: 0 30px;
    }

    @media (max-width: 1300px) {
        padding: 0 20px;
    }

    @media (max-width: 1200px) {
        padding: 0 10px;
    }

    @media (max-width: 800px) {
        padding: 0 8px;
    }

    &.topbottom {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

input {
    font-family: inherit;
}

a {
    color: rgb(31, 71, 105);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.mb2 {
    margin-bottom: 2px;
}

.mb4 {
    margin-bottom: 4px;
}

.mb6 {
    margin-bottom: 6px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb12 {
    margin-bottom: 12px;
}

.mb14 {
    margin-bottom: 14px;
}

.cursor-pointer {
    cursor: pointer;
}

pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

input.themed {
    border: none;
    outline: none;
    display: block;
    height: 100%;
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;

    &::placeholder {
        color: #555555;
    }
}

select.themed {
    height: 100%;
    background: none;
    border: none;
    font-size: 14px;
    appearance: none;
    cursor: pointer;
    outline-width: 3px;

    &::-ms-expand {
        display: none;
    }
}

button.link {
    background: none;
    border: none;
    padding: 0;
    color: #069;
    font-size: 12px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.min-height-textfield {
    min-height: 80px;
}

.lot-selector-wrapper {
    margin-left: 10px;
}

.text-preview {
    border: 1px solid variables.$theme-border-color-default;
}

.KBOEntityOption {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .secondary {
        font-size: 14px;
        color: #717171;

        &.crossedOut {
            color: red;
            text-decoration: line-through;
        }
    }

    &:hover {
        background: color.adjust(#fff, $lightness: -5%);
    }

    .loading-container {
        padding: 10px;
    }
}

.auth-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 300px;
}

.auth-form-container {
    background: white;
    width: 95%;
    max-width: 600px;
    border: 1px solid variables.$theme-border-color-default;
    padding: 20px 40px;
    position: relative;
    margin-top: -100px;
    position: relative;
}

.auth-form-error {
    color: #c0392b;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
}

.auth-form-success {
    color: #27ae60;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
}

.text-input {
    padding: 12px;
    font-size: 16px;
    width: 100%;
    outline: none;
    border: 2px solid variables.$theme-border-color-default;
    border-radius: 4px;

    &:focus {
        border-color: variables.$theme-border-color-active;
    }
}

.login-button {
    background: #305b7f;
    border-color: #305b7f;
    color: white;
    transition: all 50ms ease-in-out;
    cursor: pointer;

    &:hover,
    &.disabled {
        background: color.adjust(#305b7f, $lightness: 5%);
        border-color:color.adjust(#305b7f, $lightness: 5%); 
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.ReactModal__Body--open {
    overflow-y: hidden;
}


// CSS style for horizontal or vertical panel slide
.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
  
  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
  
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  //vertical line
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
// left arrow
  .Resizer.vertical:before {
        content: '';
        background:url('./assets/IconSet/arrowback.svg');
        position:absolute;
        width:15px;
        height:15px;
        margin-left:-10px;
        top: 55%;
        background-repeat: no-repeat;
  }
//   right arrow
  .Resizer.vertical:after {
    content: '';
    background:url('./assets/IconSet/arrowforward.svg');
    position:absolute;
    width:15px;
    height:15px;
    top: 55%;
    margin-left: 3px;
    background-repeat: no-repeat;
}
  
//   .Resizer.vertical:hover {
//     border-left: 5px solid rgba(0, 0, 0, 0.5);
//     border-right: 5px solid rgba(0, 0, 0, 0.5);
//   }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  *::-webkit-scrollbar {
    width: 0.3em;
    height:0.3em;
}
 
*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}
 
*::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
}

// SWIPER IN TENDENZ CAROUSEL

/* Override Swiper navigation button colors */
.swiper-button-next,
.swiper-button-prev {
    color: #70a4b7; /* Custom arrow color */
    border-radius: 24px;
    width: 32px;
    height: 32px;
    padding: 8px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    color: #fff; /* Hover color */
    background: #82b6a0;
}

.swiper-button-disabled {
    color: #ccc; /* Disabled button color */
    cursor: not-allowed;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
}

// Pagination active dot
.swiper-pagination-bullet-active {
    background-color: #70a4b7;
}
