.MobileHeader {
    background: var(--themeCurrent);
    color: white;

    .color{
        // background-color: yellow !important;
        height: 100%;
        overflow: auto; 
    }
    
    .toolbar {
        padding: 2px 5px;
        display: flex;
        justify-content: space-between;

        .middle {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.3rem;
        }
    }
}

