@use "../variables.scss";
@use 'sass:color';

.Login {
    .input-help {
        text-align: right;
    }

    .forgot-password {
        font-size: 12px;
        color: color.adjust(variables.$theme-border-color-default, $lightness: -50%)
        
    }

    .text-button {
        font-size: 16px;
    }

    .action-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.LoginInputField {
    position: relative;
}
