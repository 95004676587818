.NavBar {
    background: var(--themeCurrent);
    height: 100%;
    max-height: 100vh;
    position: sticky;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    cursor: pointer;
    width: 64px;
    transition: width 0.3s ease-in-out 0.2s;
}

.NavBarHover {
    background: var(--themeCurrent);
    height: 100%;
    max-height: 100vh;
    position: sticky;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    cursor: pointer;
    width: 300px;
    transition: width 0.3s ease-in-out 0.2s;
}

.columnCentered {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.iconContainer {
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
}

.tenderguideIconSvg {
    display: block;
    width: 30px;
    height:30px;
    overflow: hidden;

    * {
        fill: #e6e6e6 !important;
    }
}

.tenderguideWhite {
    * {
        fill: #e6e6e6 !important;
    }    
    rect {
        fill: none !important;
    }

}

.iconSelected {
    background-color: rgba(#fff, 0.2) !important;
}

.avatar {
    width: 30px !important;
    height: 30px !important;
    font-size: 0.9rem !important;
}

.badge {
    background-color: rgb(230, 230, 230);
}
