@use 'sass:color';

$theme-border-radius: 4px;
$theme-border-color-default: #dfdfdf;
$theme-border-color-active:  color.adjust(#dfdfdf, $lightness: -20%);
$theme-border-color-error: #f44336;
$theme-border-color-transition: 150ms border-color ease-in-out;

$theme-blue: #173357;

$completed-green: #489a19;
